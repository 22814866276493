.navbar-container{
    position: fixed;
    bottom: -20px;
    width: 100%;
    z-index: 2;
}

.navbar-container .navbar{
    list-style: none;
    display: flex;
    justify-content: center;
    margin: 0;
    background-image: url('../pics/navBar/NAVBAR.png');
    background-size: 100% 100%;
    background-position: center;
    background-repeat: no-repeat;
}
.navbar-container .navbar .mobile-toggle{
    display: none;
}
.navbar-container .navbar .item{
    margin: 20px 15px;
    display: flex;
    align-items: center;
}
.navbar-container .navbar .item h2{
    color: white;
    font-size: 20px;
}
.navbar-container .navbar .item h2:hover{
    cursor: pointer;
}
.navbar-container .navbar .item a{
    text-decoration: none;
    color: white;
}
.navbar-container .navbar .item a h2{
    color: white;
    font-size: 20px;
    text-shadow: 2px 2px 2px black;
}
.navbar-container .navbar .item h2{
    text-shadow: 2px 2px 2px black;
}
.ReactModalPortal .ReactModal__Overlay{
    background-color: rgb(56 56 56 / 0%)!important;
    transition: 1s background-color ease;
}
.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--after-open{
    background-color: rgb(56 56 56 / 75%)!important;
    transition: 1s background-color ease;
}
.ReactModalPortal .ReactModal__Overlay.ReactModal__Overlay--before-close{
    background-color: rgb(56 56 56 / 0%)!important;
    transition: 1s background-color ease;
}
body.ReactModal__Body--open .ReactModalPortal .sound-modal{
    width: 10%!important;
    height: fit-content;
    margin: auto;
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background-image: linear-gradient(#6E4525, #A47869);
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px 25px;
    animation: popModal 1s forwards ease;
}
body.ReactModal__Body--open .ReactModalPortal .sound-modal.closing{
    animation: unPop 1s forwards ease;
}
body.ReactModal__Body--open .ReactModalPortal .reach-modal{
    width: 35%!important;
    height: fit-content;
    margin: auto;
    position: absolute;
    inset: 40px;
    border: 1px solid rgb(204, 204, 204);
    background-image: linear-gradient(#6E4525, #A47869)!important;
    overflow: auto;
    border-radius: 4px;
    outline: none;
    padding: 20px 25px;
    animation: popModal 1s forwards ease;
}
body.ReactModal__Body--open .ReactModalPortal .reach-modal.closing{
    animation: unPop 1s forwards ease;
}
@keyframes popModal{
    0%{
        transform: scale(0);
    }
    100%{
        transform: scale(1);
    }
}
@keyframes unPop{
    0%{
        transform: scale(1);
    }
    100%{
        transform: scale(0);
    }
}

@media (max-width: 500px){
    .navbar-container{
        bottom: -4.5%;
    }
    .navbar-container .navbar{
        padding: 40px 20px;
        background-position: 100% 200%;
    }
    .navbar-container .navbar > *{
        justify-content: center;
        text-align: center;
    }
    .navbar-container .navbar .item{
        margin: 10px 5px;

    }
    .navbar-container .navbar .item h2{
        color: white;
        font-size: 11px;
        margin-top: 15px;
    }
    .navbar-container .navbar .item a h2{
        color: white;
        font-size: 11px;
        margin-top: 15px;
    }
    /* .navbar-container.close{
        bottom: -46%;
        transition: all 1s ease;
    }
    .navbar-container.open{
        bottom: 0;
        transition: all 1s ease;
    }
    .navbar-container .navbar{
        flex-direction: column;
        background-size: 100% 180%;
        padding: 0;
    }
    .navbar-container .navbar > *{
        justify-content: center;
        text-align: center;
    }
    .navbar-container .navbar .mobile-toggle{
        display: block;
        margin: 10px 0;
        color: white;
    }
    .navbar-container.close .navbar .mobile-toggle img{
        transform: rotate(0);
        transition: 1s ease;
    }
    .navbar-container.open .navbar .mobile-toggle img{
        transform: rotate(180deg);
        transition: 1s ease;
    }
    .navbar-container .navbar .item{
        margin: 0;
        display: flex;
        align-items: center;
    }
    .navbar-container .navbar .item h2{
        color: white;
        font-size: 15px;
    }
    .navbar-container .navbar .item a h2{
        color: white;
        font-size: 15px;
    } */
    body.ReactModal__Body--open .ReactModalPortal .sound-modal{
        width: fit-content!important;
    }
    body.ReactModal__Body--open .ReactModalPortal .reach-modal{
        width: 70%!important;
    }
}