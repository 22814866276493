
@font-face{
  font-family: 'recoleta-bold';
  src: url(./fonts/SandeMore-Regular.otf);
}
@font-face{
  font-family: 'recoleta';
  src: url(./fonts/SandeMore-Regular.otf);
}

body {
  margin: 0;
  font-family: 'recoleta';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
/* .ReactModalPortal{
  height: fit-content;
} */
.ReactModalPortal .ReactModal__Overlay{
  z-index: 2;
}
/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
html, body, #root, .app{
  /* margin-bottom: 75px; */
}
