.sounds{

}
.sounds h2{
    text-align: center;
    margin: 10px;
    color: #ffffff;
}
.sounds .social-container{
    display: flex;
    flex-direction: column;
}
.sounds .social-container .link{
    display: block;
    justify-content: center;
    text-decoration: none;
    color: #ffffff;
    text-align: center;
}
.sounds .social-container .link svg{
    margin: 10px 0;
}