/* .contact{
    position: absolute;
    width: 100%;
    height: 100%;
} */
.contact .contact-form{
    display: flex;
    flex-direction: column;
}
.contact .contact-form label{
    color: #ffffff;
}
.contact .contact-form input{
    font-family: 'recoleta';
}
.contact .contact-form .message-input{
    margin-bottom: 15px;
    height: 80px;
    font-family: 'recoleta';
}
.contact .contact-form .submit-butt{
    display: flex;
    padding: 10px 30px;
    text-decoration: none;
    font-size: 40px;
    color: white;
    background: #39434F;
    transition: 1s;
    box-shadow: 3px 3px 0 #c9c9c9a4;
    width: fit-content;
    align-items: center;
    margin: auto;
    margin-top: 15px;
    border: none;
}
.contact .contact-form .submit-butt:focus{
    outline: none;
}
.contact .contact-form .submit-butt:hover{
    transition: 0.5s;
    box-shadow: 10px 10px 0 #624015;
    cursor: pointer;
}
.contact .contact-form .submit-butt span:nth-child(2){
    transition: 0.5s;
    margin-right: 0px;
}
.contact .contact-form .submit-butt:hover span:nth-child(2){
    transition: 0.5s;
    margin-right: 10px;
}
.contact .contact-form .submit-butt span{
    font-family: 'recoleta-bold';
    font-size: 15px;
}
.contact .contact-form .submit-butt span:nth-child(2){
    width: 20px;
    /* margin-left: 30px; */
    position: relative;
    top: 0px;
}
.contact .contact-form .submit-butt span path.one{
    transition: 0.4s;
    transform: translateX(-60%);
}
.contact .contact-form .submit-butt span path.two{
    transition: 0.5s;
    transform: translateX(-30%);
}
.contact .contact-form .submit-butt:hover span path.three{
    animation: color_anim 1s infinite 0.2s;
}
.contact .contact-form .submit-butt:hover span path.one{
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.6s;
}
.contact .contact-form .submit-butt:hover span path.two{
    transform: translateX(0%);
    animation: color_anim 1s infinite 0.4s;
}